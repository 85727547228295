<template>
  <div class="transfer">
    <v-card :loading="loading" class="mb-3">
      <v-card-title>
        Transfer Bank

        <v-spacer></v-spacer>

        <v-btn
          v-if="transfer != null"
          :to="`/accounting/bank-transfers/${transfer.trans_no}/edit`"
          color="primary"
          large
        >
          Edit
        </v-btn>
      </v-card-title>

      <v-divider></v-divider>

      <template v-if="!loading && transfer != null">
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td>No Transaksi :</td>
                  <td>{{ transfer.trans_no }}</td>
                </tr>

                <tr>
                  <td>Referensi :</td>
                  <td>{{ transfer.ref }}</td>
                </tr>

                <tr>
                  <td>Tanggal :</td>
                  <td>{{ sql2date(transfer.trans_date) }}</td>
                </tr>

                <tr>
                  <td>Untuk :</td>
                  <td>
                    <span v-if="transfer.person_type_id == 0">
                      {{ transfer.person_id }}
                    </span>
                    <span v-if="transfer.person_type_id == 2"
                      >Customer - {{ transfer.person_name }}</span
                    >
                    <span v-if="transfer.person_type_id == 3"
                      >Supplier - {{ transfer.person_name }}</span
                    >
                  </td>
                </tr>

                <tr>
                  <td>Nilai :</td>
                  <td>{{ currencyFormat(transfer.amount) }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </template>
    </v-card>
  </div>
</template>

<script>
export default {
  data: () => ({
    loading: false,
    transfer: null,
  }),

  computed: {
    transNo() {
      return this.$route.params.transNo
    },
  },

  mounted() {
    this.fetch()
  },

  methods: {
    currencyFormat(value) {
      return Intl.NumberFormat('id-ID', {
        currency: 'IDR',
      }).format(value)
    },

    fetch() {
      this.loading = true

      this.$http
        .get('/accounting/bank-transfers/' + this.transNo)
        .then(({ data }) => {
          this.transfer = data

          if (this.transfer.amount < 0) {
            this.transfer.amount *= -1
          }
        })
        .catch((err) => {
          console.log(err)
        })
        .then(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.fit {
  width: 1%;
  white-space: nowrap;
}
</style>
